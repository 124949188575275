

































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import { Location } from "vue-router";

interface FaqItem {
    name: string;
    route: Location;
}

@Component({
    components: { BackButton },
})
export default class FaqIndex extends Vue {
    menuItems: Array<FaqItem> = [
        {
            name: "What is my DSTV smartcard/IUC number?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_WHAT_IS_MY_DSTV_SMARTCARD_NUMBER,
            },
        },
        {
            name: "How to check your DStv smartcard number?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_HOW_TO_CHECK_YOUR_DSTV_SMARTCARD_NUMBER,
            },
        },
        {
            name: "What payment options are available on Nyanga Pay?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_WHAT_PAYMENT_OPTIONS_ARE_AVAILABLE_ON_NYANGA_PAY,
            },
        },
        {
            name: "Why was I not reconnected immediately after a successful payment?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_WHY_WAS_I_NOT_RECONNECTED_AFTER_SUCCESSFUL_PAYMENT,
            },
        },
        {
            name: "How do I login to my DStv account?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_HOW_DO_I_LOGIN_TO_MY_DSTV_ACCOUNT,
            },
        },
        {
            name: "How do I upgrade or downgrade my DStv Package?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_HOW_DO_I_UPGRADE_OR_DOWNGRADE_MY_DSTV_PACKAGE,
            },
        },
        {
            name: "Why are my DStv channels not showing?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_WHY_ARE_MY_DSTV_CHANNELS_NOT_SHOWING,
            },
        },
        {
            name: "What do I do if my Dstv channels are not showing?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_WHAT_DO_I_DO_IF_MY_DSTV_CHANNELS_ARE_NOT_SHOWING,
            },
        },
        {
            name: "How do I Clear E16, E32, or E32 error messages on my DStv decoder?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_HOW_DO_I_CLEAR_E16_E30_E32_ERROR_MESSAGES_ON_MY_DSTV_DECODER,
            },
        },
        {
            name: "Why do I get E48-32 on my Tv?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_WHY_DO_I_GET_E48_32_ON_MY_TV,
            },
        },
        {
            name: "How do I clear E48-32 error on my TV?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_HOW_DO_I_CLEAR_E48_32_ERROR_MESSAGES_ON_MY_DSTV_DECODER,
            },
        },
        {
            name: "Can Nyanga pay automatically withdraw money from my mobile money account?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_CAN_NYANGA_PAY_AUTOMATICALLY_WITHDRAW_MONEY_FROM_MY_MOBILE_MONEY_ACCOUNT,
            },
        },
        {
            name: "Money has been deducted from my Mobile Money account but the transaction was not successful?",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_MONEY_HAS_BEEN_DEDUCTED_FROM_MY_ACCOUNT_BUT_THE_TRANSACTION_WAS_NOT_SUCCESSFUL,
            },
        },
        {
            name: "What is the DStv XtraView",
            route: {
                name: this.$constants.ROUTE_NAMES.FAQ_WHAT_IS_DSTV_XTRAVIEW,
            },
        },
        {
            name: "What is the DStv price lock feature",
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_PRICE_LOCK,
            },
        },
        {
            name: "DStv Price Update - November 2022",
            route: {
                name: this.$constants.ROUTE_NAMES
                    .FAQ_NOVEMBER_2022_PRICE_UPDATE,
            },
        },
    ];
}
